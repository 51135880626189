<template>
  <div class="my-orders" v-loading="loading">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;padding:20px;">
        <div style="display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">保函状态：</div>
          <div>
            <el-select v-model="guaranteeType" placeholder="请选择保函状态" style="width: 220px;height: 32px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">保函编号：</div>
          <div>
            <el-input v-model="policySn" style="width: 220px;height: 32px;" placeholder='请输入保函编号' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">订单编号：</div>
          <div>
            <el-input v-model="orderId" style="width: 220px;height: 32px;" placeholder='请输入订单编号' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">项目名称：</div>
          <div>
            <el-input v-model="projectName" style="width: 220px;height: 32px;" placeholder='请输入项目名称' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">承保公司：</div>
          <div>
            <el-input v-model="institutionName" style="width: 220px;height: 32px;" placeholder='请输入承保公司' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;flex-direction: row-reverse;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">
            <el-button @click="SearchBtn" type="primary" size="default" :icon="Search" style="width: 78px;height: 32px;">搜索</el-button>
            <el-button @click="Reset" size="default" :icon="RefreshLeft" style="width: 78px;height: 32px;margin-left:10px;">重置</el-button>
          </div>
        </div>
      </div>
      <div>
        <div class="box" v-for="item in ordersArr" :key="item.id">
          <div class="top-box" style="display: flex;justify-content: space-between;">
            <div class="top-box">
              <div class="status">{{StatusTitle(item.isCompensation,item.expireStatus)}}</div>
              <div class="line"></div>
              <div class="name">保函编号：{{item.policySn}}</div>
            </div>
            <div style="margin-right:30px;">
              <div class="status">{{item.applyWay == 'readyMoney' ? '现金缴存' : item.applyWay == 'bank'?'银行保函': item.applyWay == 'bond'?'保证保险':'担保保函'}}</div>
            </div>
          </div>
          <div class="bottom-box">
            <div class="text-status">
              <div class="flex">
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">保险期限：</div>
                  <div class="text box1" v-if="item.startTime&&item.endTime">
                    {{item.startTime.slice(0,11)}} 至 {{item.endTime.slice(0,11)}}
                  </div>
                  <div class="text box1" v-else>
                    暂无保险期限
                  </div>
                </div>
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">项目名称：</div>
                  <div class="text box1" v-if="item.projectName">
                    <el-tooltip class="box-item" effect="dark" :content="item.projectName" placement="top">
                      {{item.projectName}}
                    </el-tooltip>
                  </div>
                  <div class="text box1" v-else>
                    暂无项目名称
                  </div>
                </div>
              </div>
              <div class="flex">
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">承保公司：</div>
                  <div class="text box3" v-if="item.institutionName">
                    <el-tooltip class="box-item" effect="dark" :content="item.institutionName" placement="top">
                      {{item.institutionName}}
                    </el-tooltip>
                  </div>
                  <div v-else>
                    暂无承保公司
                  </div>
                </div>
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">订单编号：</div>
                  <div class="text box3" v-if="item.orderId">
                    <el-tooltip class="box-item" effect="dark" :content="item.orderId" placement="top">
                      {{item.orderId}}
                    </el-tooltip>
                  </div>
                  <div class="text box3" v-else>
                    暂无订单编号
                  </div>
                </div>
              </div>
            </div>
            <div class="box-line"></div>
            <div class="btn-arr">
              <el-button type="primary" size="default" :icon="View" class="btn-7" v-if="item.isCompensation==3" @click="ViewDetails(item.applyCompensationId)">理赔详情</el-button>
              <el-button type="primary" size="default" :icon="Download" class="btn-7" v-if="item.applyWay!='bank'" @click="DownloadBh(item.policyUrl)">
                下载保函
              </el-button>
              <el-button type="primary" size="default" :icon="Tickets" class="btn-7" @click="goInvoiceApplication(item.projectId)">
                立即续保
              </el-button>
            </div>
          </div>
        </div>
        <div class="demo-pagination-block">
          <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="理赔详情" width="55%" top="5vh">
      <el-form ref="ruleFormRef" :model="ruleForm" :inline="true" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon>
        <div style="width: 100%;display: flex;justify-content: center;font-size: 20px;color: #000;font-weight: 700;margin-bottom: 20px;">发起信息</div>
        <el-form-item label="订单编号：">
          <el-input disabled v-model="ruleForm.orderSn" placeholder="请输入订单编号" class="w260" />
        </el-form-item>
        <el-form-item label="保单编号：">
          <el-input disabled v-model="ruleForm.guaranteeSn" placeholder="请输入保单编号" class="w260" />
        </el-form-item>
        <el-form-item label="赔付金额(元)：">
          <el-input disabled v-model="ruleForm.applyCompensationAmount" placeholder="请输入赔付金额" class="w260" />
        </el-form-item>
        <el-form-item label="收款账号：">
          <el-input disabled v-model="ruleForm.bankAccount" placeholder="请输入收款账号" class="w260" />
        </el-form-item>
        <el-form-item label="发起理赔联系人：">
          <el-input disabled v-model="ruleForm.compensationContacts" placeholder="请输入发起理赔联系人" class="w260" />
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input disabled v-model="ruleForm.compensationContactPhone" placeholder="请输入联系方式" class="w260" />
        </el-form-item>
        <el-form-item label="索赔原因：" style="width: 100%;">
          <el-input disabled v-model="ruleForm.compensationMsg" type="textarea" placeholder="请输入索赔原因" style="width: 100%;min-height:100px;" />
        </el-form-item>
        <el-form-item label="附件：" style="width: 100%;">
          <div>
            <div style="color: rgb(0, 82, 217);" v-for="item in ruleForm.attachList" :key="item.attId" @click="fjDone(item.name)">{{item.originalName}}</div>
          </div>
        </el-form-item>
        <div v-if="Object.keys(ruleFormX).length !== 0">
          <div style="width: 100%;display: flex;justify-content: center;font-size: 20px;color: #000;font-weight: 700;margin-bottom: 20px;">理赔信息</div>
          <el-form-item label="赔付人名称：">
            <el-input disabled v-model="ruleFormX.name" placeholder="请输入赔付人名称" class="w260" />
          </el-form-item>
          <el-form-item label="赔付人统一社会信用代码：">
            <el-input disabled v-model="ruleFormX.creditCode" placeholder="请输入赔付人统一社会信用代码" class="w260" />
          </el-form-item>
          <el-form-item label="赔付人联系人：">
            <el-input disabled v-model="ruleFormX.contacts" placeholder="请输入赔付人联系人" class="w260" />
          </el-form-item>
          <el-form-item label="赔付人联系人手机号：">
            <el-input disabled v-model="ruleFormX.contactPhone" placeholder="请输入赔付人联系人手机号" class="w260" />
          </el-form-item>
          <el-form-item label="赔付银行账号：">
            <el-input disabled v-model="ruleFormX.bankAccount" placeholder="请输入赔付银行账号" class="w260" />
          </el-form-item>
          <el-form-item label="赔付银行开户行：">
            <el-input disabled v-model="ruleFormX.bank" placeholder="请输入赔付银行开户行" class="w260" />
          </el-form-item>
          <el-form-item label="受益人名称：">
            <el-input disabled v-model="ruleFormX.beneficiary" placeholder="请输入受益人名称" class="w260" />
          </el-form-item>
          <el-form-item label="受益人统一社会信用代码：">
            <el-input disabled v-model="ruleFormX.beneficiaryCreditCode" placeholder="请输入受益人统一社会信用代" class="w260" />
          </el-form-item>
          <el-form-item label="受益人联系人：">
            <el-input disabled v-model="ruleFormX.beneficiaryContacts" placeholder="请输入受益人联系人" class="w260" />
          </el-form-item>
          <el-form-item label="受益人联系人手机号：">
            <el-input disabled v-model="ruleFormX.beneficiaryContactPhone" placeholder="请输入受益人联系人手机号" class="w260" />
          </el-form-item>
          <el-form-item label="受益人银行账号：">
            <el-input disabled v-model="ruleFormX.beneficiaryBankAccount" placeholder="请输入受益人银行账号" class="w260" />
          </el-form-item>
          <el-form-item label="受益人银行开户行：">
            <el-input disabled v-model="ruleFormX.beneficiaryBank" placeholder="请输入受益人银行开户行" class="w260" />
          </el-form-item>
          <el-form-item label="赔偿金额(元)：">
            <el-input disabled v-model="ruleFormX.compensationAmount" placeholder="请输入赔偿金额" class="w260" />
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <div class="dialog-footer" style="display: flex;justify-content: center;align-items: center">
          <el-button type="primary" @click="dialogVisible = false" style="width: 100px;height:40px;">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { RefreshLeft, Search, View, Download, Tickets } from '@element-plus/icons-vue'
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from 'element-plus'
import { selfGuaranteeOrder, selfCompensation } from "@/api/index.js"
let router = useRouter()
let loading = ref(true)
let dialogVisible = ref(false)
let projectName = ref("")
let guaranteeType = ref("")
let policySn = ref("")
let orderId = ref("")
let institutionName = ref("")
let currentPage = ref(1)
let pageSize = ref(10)
let size = ref('default')
let disabled = ref(false)
let total = ref()
let ordersArr = ref()
let formSize = ref('default')
let ruleFormRef = ref()
let trueSearch = ref(false)
let ruleForm = ref({})
let ruleFormX = ref({})
let myApplyCompensationId = ref()
let options = ref([
  { value: '1', label: '已出函' },
  { value: '2', label: '已过期' },
  { value: '3', label: '理赔中' },
  { value: '4', label: '已理赔' },
])
let handleSizeChange = (val) => {
  pageSize.value = val
  AlloedersArr()
  window.scrollTo(0, 0)
}
let handleCurrentChange = (val) => {
  if (trueSearch.value) {
    currentPage.value = val
    AlloedersArr()
  } else {
    projectName.value = ''
    guaranteeType.value = ''
    policySn.value = ''
    orderId.value = ''
    institutionName.value = ''
    currentPage.value = val
    AlloedersArr()
  }
  window.scrollTo(0, 0)
}
let goInvoiceApplication = (projectId) => {
  router.push({ path: '/deposit', query: { projectId: projectId, type: '1' } })
}
let ViewDetails = (applyCompensationId) => {
  dialogVisible.value = true
  myApplyCompensationId.value = applyCompensationId
  selfCompensationMes()
}
let AlloedersArr = async () => {
  await selfGuaranteeOrder({ current: currentPage.value, size: pageSize.value, policySn: policySn.value, orderId: orderId.value, projectName: projectName.value, institutionName: institutionName.value, guaranteeType: guaranteeType.value }).then((res) => {
    ordersArr.value = res.data.records
    total.value = res.data.total
  })
}
let StatusTitle = (isCompensation, expireStatus) => {
  if (isCompensation == '3') {
    return "已理赔"
  } else if (isCompensation == '1' || isCompensation == '2') {
    return "理赔中"
  } else if (expireStatus == '1') {
    return "已出函"
  } else if (expireStatus == '99') {
    return "已过期"
  } else {
    return "暂无状态"
  }
}
let SearchBtn = async () => {
  trueSearch.value = true
  await AlloedersArr()
}
let Reset = async () => {
  projectName.value = ''
  guaranteeType.value = ''
  policySn.value = ''
  orderId.value = ''
  institutionName.value = ''
  await AlloedersArr()
}
let DownloadBh = (url) => {
  window.open(url)
}
let selfCompensationMes = async () => {
  await selfCompensation({ id: myApplyCompensationId.value }).then((res) => {
    console.log(res, 'res123');
    ruleForm.value = res.data
    ruleFormX.value = res.data.compensation
  })
}
let fjDone = (url) => {
  const currentUrl = window.location.href;
  const parts = currentUrl.split('/');
  const baseUrl = `${parts[0]}//${parts[2]}`;
  window.open(`${baseUrl}` + '/' + `${url}`)
}
onMounted(async () => {
  await AlloedersArr()
  loading.value = false
})
</script>
<style scoped>
.w260 {
  width: 260px;
  height: 40px;
}
::v-deep .el-select__wrapper {
  height: 100%;
}
::v-deep .el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.w260 {
  width: 260px;
}
.red-tip {
  height: 52px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
  text-align: center;
  line-height: 52px;
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
::v-deep .el-textarea__inner {
  min-height: 100px !important;
}
.demo-pagination-block {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
}
::v-deep .el-button + .el-button {
  margin-left: 0;
}
::v-deep .el-icon-devops {
  background: url("./../../static/piao.png") center no-repeat;
  font-size: 12px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devops:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
::v-deep .el-icon-devopsX {
  background: url("./../../static/jf.png") center no-repeat;
  font-size: 12px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devopsX:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
::v-deep .el-icon-devopsS {
  background: url("./../../static/tui.png") center no-repeat;
  font-size: 20px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devopsS:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
.tit {
  white-space: nowrap;
}
.btn-7 {
  width: 84px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(0, 82, 217, 1);
}
.my-orders {
  padding: 15px;
  box-sizing: border-box;
}
.red {
  color: rgb(212, 48, 48) !important;
}
.green {
  color: rgb(0, 186, 173) !important;
}
.yellow {
  color: rgb(255, 141, 26) !important;
}
.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 18%;
}
.flex:nth-child(2) {
  margin-right: 0;
}
.btn-arr {
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.box1 {
  max-width: 188px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box2 {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box3 {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text {
  font-size: 14px;
  font-weight: 400;
  color: rgb(32, 35, 50);
  text-align: left;
}
.box-line {
  width: 0px;
  height: 100px;
  border: 1px solid rgba(243, 246, 255, 1);
  display: flex;
  align-self: center;
  margin: 0 30px;
}
.text-status {
  min-width: 73%;
  display: flex;
}
.box {
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 38px;
}
.bottom-box {
  height: 140px;
  display: flex;
}
.top-box {
  display: flex;
  align-items: center;
  height: 58px;
  border-radius: 4px 4px 0px 0px;
  background: rgb(243, 246, 255);
}
.status {
  font-size: 22px;
  font-weight: 500;
  color: rgba(31, 112, 247, 1);
  margin-left: 23px;
}
.name {
  font-size: 18px;
  font-weight: 500;
  color: rgba(32, 35, 50, 1);
}
.line {
  width: 2px;
  height: 30px;
  border-radius: 2px;
  background: rgba(194, 198, 206, 1);
  margin: 0 30px 0 35px;
}
</style>